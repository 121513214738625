import * as React from "react"
import Helmet from "react-helmet"
import tw, { styled, css } from "twin.macro"
import Layout from "components/layout"
import Cover from "images/cover.jpg"

const IndexPage = () => {
  const Section = styled.section(
    tw`min-h-screen pb-5 bg-center bg-cover pt-36 md:pt-48`,
    css`
      background-image: URL(${Cover});
    `
  )
  const Container = tw.div`container !max-w-[732px]`
  const Content = tw.p`leading-tight max-w-[520px] mb-4 last:(mb-0) mx-auto text-center`
  const Widget = tw.iframe`border-0 h-[800px] max-w-[692px] mb-24 mt-8 mx-auto w-full`
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Book | Otto</title>
        <meta
          name="description"
          content="Make your reservation at Otto using our ResDiary&trade; form. If your party size is larger than six people, please contact the restaurant to discuss your requirements. Reservations placed through Facebook Messenger will not be processed."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Section>
        <Container>
          <h1>Book</h1>
          <Content css={tw`text-base sm:text-xl`}>
            Make your reservation at Otto using our ResDiary&trade; form.
          </Content>
          <Content css={tw`mb-3 text-lg uppercase sm:text-xl`}>
            If your party size is larger than six people, please call us on{" "}
            <a href="tel:+441481710888">710888</a> to discuss your requirements.
          </Content>
          <Content>
            Reservations of more than six people placed through this form will not be processed.
            Reservations placed through Facebook Messenger will not be processed.
          </Content>
          <Widget
            src="https://booking.resdiary.com/widget/Standard/Otto/30485?"
            allowtransparency="true"
            frameborder="0"
          ></Widget>
        </Container>
      </Section>
    </Layout>
  )
}

export default IndexPage
