import React from "react"
import { Global, css } from "@emotion/react"
import tw, { GlobalStyles as BaseStyles } from "twin.macro"

const Styles = css`
  body {
    ${tw`antialiased bg-green font-barlow text-white`}
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    ${tw`font-quartsPachino text-center`}
  }
  h1 {
    ${tw`text-6xl`}
  }
  a {
    ${tw`transition duration-500 ease-in-out`}
  }
`

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={Styles} />
  </>
)

export default GlobalStyles
